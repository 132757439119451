<template>
  <div id="manual" :style="{ backgroundColor: 'black' }">
    <div>
      <div :style="{ width: pdfDivWidth, margin: '0 auto' }">
        <canvas v-for="page in canvasNum" :id="'pdf-canvas' + page" :key="page"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import PDFJS from 'pdfjs-dist'
import { defineComponent, nextTick, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry.js')

export default defineComponent({
  setup() {
    const route = useRoute()
    const routerQuery = route.query
    const pdfScale = 1.25
    const pdfDivWidth = ref('600px')
    const canvasNum = ref(0)
    const pdfSrc = '/static/manual.pdf'

    const getPdfUrl = async () => {
      console.log(routerQuery)
      const { pageNumber, totalPages } = routerQuery
      const pdfDoc = await PDFJS.getDocument(pdfSrc)
      const startPage = pageNumber ? parseInt(pageNumber) : 1
      const endPage = totalPages ? startPage + parseInt(totalPages) - 1 : pdfDoc.numPages
      canvasNum.value = endPage - startPage + 1
      nextTick(() => {
        renderPage(pdfDoc, startPage, endPage, 1)
      })
    }

    const renderPage = async (pdfDoc, startPage, endPage, canvasIdx) => {
      const page = await pdfDoc.getPage(startPage)
      const canvas = document.getElementById('pdf-canvas' + canvasIdx)
      if (!canvas) {
        return
      }
      const ctx = canvas.getContext('2d')
      const dpr = window.devicePixelRatio || 1
      const bsr =
        ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio ||
        1
      const ratio = dpr / bsr
      // const ratio = 1
      const viewport = page.getViewport({ scale: pdfScale })
      canvas.width = viewport.width * ratio
      canvas.height = viewport.height * ratio
      canvas.style.width = viewport.width + 'px'
      canvas.style.height = viewport.height + 'px'
      pdfDivWidth.value = viewport.width + 'px'
      ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
      const renderContext = {
        canvasContext: ctx,
        viewport: viewport
      }
      page.render(renderContext)
      if (endPage >= startPage) {
        renderPage(pdfDoc, startPage + 1, endPage, canvasIdx + 1)
      }
    }

    onMounted(async () => {
      await getPdfUrl()
    })

    return {
      canvasNum,
      pdfDivWidth
    }
  }
})
</script>
