import { render } from "./ManualView.vue?vue&type=template&id=df869076"
import script from "./ManualView.vue?vue&type=script&lang=js"
export * from "./ManualView.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "df869076"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('df869076', script)) {
    api.reload('df869076', script)
  }
  
  module.hot.accept("./ManualView.vue?vue&type=template&id=df869076", () => {
    api.rerender('df869076', render)
  })

}

script.__file = "src/views/ManualView.vue"

export default script